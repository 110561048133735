<template lang="html">
	<article class="step">
		<h2>{{ $t(`${sector}.a3.title`) }}</h2>
		<p>{{ $t(`${sector}.a3.desc`) }}</p>
		<step-spectators :sector="sector" abast="a3" />
		<step-diet :sector="sector" abast="a3" />
		<step-waste-bags :sector="sector" abast="a3" />
		<step-water :sector="sector" abast="a3" />
	</article>
</template>

<script>
import StepMixin from '@/mixins/StepMixin';
import StepSpectators from '../blocks/StepSpectators.vue';
import StepDiet from '../blocks/StepDiet.vue';
import StepWasteBags from '../blocks/StepWasteBags.vue';
import StepWater from '../blocks/StepWater.vue';

export default {
	name: 'StepA3',
	mixins: [StepMixin],
	components: {
		StepSpectators,
		StepDiet,
		StepWasteBags,
		StepWater,
	},
	mounted() { this.$emit('validate', true); },
};
</script>
