var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"block"},[_c('h3',[_vm._v(_vm._s(_vm.$t((_vm.sector + "." + _vm.abast + ".waste.title"))))]),(_vm.isReportEditable)?_c('p',{staticClass:"note"},[_vm._v(_vm._s(_vm.$t((_vm.sector + "." + _vm.abast + ".waste.desc"))))]):_vm._e(),(!_vm.skip)?_c('emissions-table',{attrs:{"editable":_vm.isReportEditable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('th',[_vm._v(_vm._s(_vm.$t('report.table.concept')))]),_c('th',[_vm._v(_vm._s(_vm.$t('report.table.waste')))]),_c('th',[_c('span',{attrs:{"data-tooltip-alert":_vm.$t('tooltip.waste_bags')}},[_c('i',{staticClass:"mdi mdi-alert-circle icon icon--emphasis"})]),_vm._v(" "+_vm._s(_vm.$t('report.table.qty'))+" ")])]},proxy:true},{key:"body",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.concept))]),_c('td',[_vm._v(_vm._s(item.resource.label))]),_c('td',[_vm._v(_vm._s(item.mult)),_c('em',{staticClass:"unit"},[_vm._v(_vm._s(_vm.$t('report.table.bags')))])])]}},{key:"insert",fn:function(ref){
var insert = ref.insert;
return [_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(insert.concept),expression:"insert.concept"}],staticClass:"long",attrs:{"type":"text","placeholder":_vm.$t((_vm.sector + "." + _vm.abast + ".waste.example"))},domProps:{"value":(insert.concept)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(insert, "concept", $event.target.value)}}})]),_c('td',[_c('dropdown-select',{attrs:{"options":_vm.WASTE,"placeholder":_vm.$t('report.table.waste')},on:{"input":function($event){insert.qty = insert.resource.density}},model:{value:(insert.resource),callback:function ($$v) {_vm.$set(insert, "resource", $$v)},expression:"insert.resource"}})],1),_c('td',{attrs:{"data-tooltip":_vm.isEmpty(insert.resource)
				? _vm.$t('tooltip.require.waste')
				: _vm.$t('tooltip.unit_in', { unit: _vm.$t('report.table.bags') })}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(insert.mult),expression:"insert.mult"}],attrs:{"type":"number","disabled":_vm.isEmpty(insert.resource),"placeholder":_vm.isEmpty(insert.resource)
						? _vm.$t('report.table.qty')
						: _vm.$t('report.table.bags')},domProps:{"value":(insert.mult)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(insert, "mult", $event.target.value)}}})])]}}],null,false,3796939175),model:{value:(_vm.emissions),callback:function ($$v) {_vm.emissions=$$v},expression:"emissions"}}):_vm._e(),(_vm.isSkipperVisible)?_c('p',[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.skip),expression:"skip"}],attrs:{"type":"checkbox","disabled":!_vm.isReportEditable},domProps:{"checked":Array.isArray(_vm.skip)?_vm._i(_vm.skip,null)>-1:(_vm.skip)},on:{"change":function($event){var $$a=_vm.skip,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.skip=$$a.concat([$$v]))}else{$$i>-1&&(_vm.skip=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.skip=$$c}}}}),_vm._v(" "+_vm._s(_vm.$t('global.tip.skip'))+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }