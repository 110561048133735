<template lang="html">
	<section class="block">
		<h3>{{ $t(`${sector}.${abast}.waste.title`) }}</h3>
		<p v-if="isReportEditable" class="note">{{ $t(`${sector}.${abast}.waste.desc`) }}</p>
		<emissions-table v-if="!skip" v-model="emissions" :editable="isReportEditable">
			<template v-slot:header>
				<th>{{ $t('report.table.concept') }}</th>
				<th>{{ $t('report.table.waste') }}</th>
				<th>
					<span :data-tooltip-alert="$t('tooltip.waste_bags')">
						<i class="mdi mdi-alert-circle icon icon--emphasis" />
					</span>
					{{ $t('report.table.qty') }}
				</th>
			</template>
			<template v-slot:body="{ item }">
				<td>{{ item.concept }}</td>
				<td>{{ item.resource.label }}</td>
				<td>{{ item.mult }}<em class="unit">{{ $t('report.table.bags') }}</em></td>
			</template>
			<template v-slot:insert="{ insert }">
				<td>
					<input
						type="text"
						class="long"
						v-model="insert.concept"
						:placeholder="$t(`${sector}.${abast}.waste.example`)" />
				</td>
				<td>
					<dropdown-select
						:options="WASTE"
						v-model="insert.resource"
						@input="insert.qty = insert.resource.density"
						:placeholder="$t('report.table.waste')" />
				</td>
				<td :data-tooltip="isEmpty(insert.resource)
					? $t('tooltip.require.waste')
					: $t('tooltip.unit_in', { unit: $t('report.table.bags') })">
					<input
						type="number"
						v-model="insert.mult"
						:disabled="isEmpty(insert.resource)"
						:placeholder="isEmpty(insert.resource)
							? $t('report.table.qty')
							: $t('report.table.bags')" />
				</td>
			</template>
		</emissions-table>
		<p v-if="isSkipperVisible">
			<label>
				<input type="checkbox" v-model="skip" :disabled="!isReportEditable" />
				{{ $t('global.tip.skip') }}
			</label>
		</p>
	</section>
</template>

<script>
import StepBlockMixin from '@/mixins/StepBlockMixin';
import EmissionsTable from '@/components/EmissionsTable.vue';
import DropdownSelect from '@/components/DropdownSelect.vue';
import { isEmpty } from '@/utils';
import { WASTE } from '@/assets/constants/resources';

export default {
	name: 'StepWasteBags',
  keyBlock: 'waste',
	mixins: [StepBlockMixin],
	components: { EmissionsTable, DropdownSelect },
	data() {
		return { WASTE: WASTE.filter(({ extended }) => !extended) };
	},
	methods: { isEmpty },
};
</script>
